@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables.scss" as *; 
article.hero-unit{
    h1, h2 {
        margin: 0;
    }

    h1{
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.25;
    }

    h2{
        font-weight: 300;
    }

    .q-btn{
        font-weight: bold;
        padding: $padding ($padding * 2.5);
    }

    .q-img {
        :deep(img){
            border: math.div($padding, 2) solid $light-on-light;
            border-radius: math.div($padding, 4);
            overflow: hidden;

            box-shadow:
                0 0.4px 1.3px rgba(0, 0, 0, 0.045),
                0 1.1px 3.2px rgba(0, 0, 0, 0.065),
                0 2px 6px rgba(0, 0, 0, 0.08),
                0 3.6px 10.7px rgba(0, 0, 0, 0.095),
                0 6.7px 20.1px rgba(0, 0, 0, 0.115),
                0 16px 48px rgba(0, 0, 0, 0.16)
            ;
        }

        @media (max-width: $mobile-breakpoint) {
            max-height: 400px;
        }
    }

    &:first-child{
        background: $primary;
        color: white;

        h2{
            color: $light-on-primary;
        }
    }

    @media (max-width: $mobile-breakpoint) {
        &:not(:last-of-type){
            border-bottom: 1px solid $light-on-light;
        }
    }

    .container{
        padding-top: $vspace;
        padding-bottom: $vspace;

        display: grid;
        grid-template-columns: calc(50% - #{$hspace}) calc(50% - #{$hspace});
        align-items: center;
        align-content: center;
        gap: $hspace * 2;

        @mixin vertical-container{
            grid-template-columns: 100%;
            text-align: center;

            .flex-hgap{
                justify-content: center;
                gap: $padding * 2;
            }
        }

        &.vertical{
            @include vertical-container;
        }

        @media (max-width: $mobile-breakpoint) {
            @include vertical-container;
        }
    }

    &:not(:first-child){
        .container{
            animation-duration: 1s;
            animation-fill-mode: both;
            transform: translate3d(-100%, 0, 0);
        }
        &.visible{
            .container{
                animation-name: fadeInLeft;

                @keyframes fadeInLeft {
                    0% {
                        opacity: 0;
                        transform: translate3d(-100%, 0, 0);
                    }
                    100% {
                        opacity: 1;
                        transform: translateZ(0);
                    }
                }
            }
        }
    }

    &:nth-child(2) .container{
        direction: rtl;

        > section{
            direction: ltr;
        }
    }
}
