@use 'sass:color';
@use 'sass:math';

// ----------------------------------------------------------------------------
// App
// ----------------------------------------------------------------------------

$transition: 0.25s;
$ratio: 1.618;
$padding: 16px;
$hspace: $padding * 2;
$vspace: $padding * 8;

$header-line-height: 24px;
$header-btn-size: $header-line-height + (2 * $padding);
$header-height: $header-line-height + (4 * $padding);

$mobile-breakpoint: 1280px - 1;
$max-container-width: 1280px;
$container-width: math.div(1, $ratio) * 100%;

// ----------------------------------------------------------------------------
// App Colors
// ----------------------------------------------------------------------------

$light-on-primary: rgba(255, 255, 255, 0.75);
$light-on-dark:    rgba(255, 255, 255, 0.2);
$light-on-light:   rgba(22, 22, 22, 0.1);


// ----------------------------------------------------------------------------
// Quasar
// ----------------------------------------------------------------------------

$primary   : #2e51a2;
$secondary : color.adjust($primary, $lightness: 20%);
$accent    : color.adjust($primary, $lightness: -20%);

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;

$dark      : #1d1d1d;
$dark-page : #121212;
